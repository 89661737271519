const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.emeco.forwoodsafety.com',
    APP_CLIENT_ID: 'vh39a3su102htptcvt7i6msur',
    USER_TOKEN_URL: 'https://9406u7zm9a.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://b3ohpr90f0.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.staging.emeco.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.staging.emeco.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.staging.emeco.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.emeco.forwoodsafety.com',
    WEBSOCKET: 'wss://omibhrg5zl.execute-api.us-west-2.amazonaws.com/staging'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;